import React, { Component } from 'react';
import './home.scss';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Nav from '../../components/nav/nav';
import Clock from '../../components/clock/clock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faCcVisa, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faWifi, faDesktop, faTrain, faBath, faShoppingBag, faShoppingCart, faCarAlt, faPhoneSquare, faPowerOff, faTemperatureLow, faShip, faTaxi, faCameraRetro, faLeaf, faToilet, faBinoculars, faBeer, faClock } from '@fortawesome/free-solid-svg-icons';
import BannerCarousel from '../../components/courosel/courosel';
import BannerCarousel2 from '../../components/courosel2/courosel2';
import CurrencyConverter from '../../components/currencyconverter/currencyConverter';
const config = require('../../config');
class Home extends Component {
  constructor() {
    super();
    this.state = {
      currDate: new Date().toDateString(),
      currTime: this.formatAMPM(new Date()),
      username: '',
      phone: '',
      email: '',
      msg: ''
    };
  }
  componentDidMount() {
    setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.currTime);
  }
  sendmail = () => {
    let sObj = JSON.stringify({
      "first_name": this.state.username,
      "email": this.state.email,
      "telephone": this.state.phone,
      "comments": this.state.msg
    });
    const requestOptions = {
      method: 'POST',
      body: sObj
    };
    // fetch(config['baseurl'] + 'send.php', requestOptions);
    // this.setState({
    //   username: '',
    //   phone: '',
    //   email: '',
    //   msg: ''
    // });
  }
  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  tick() {
    this.setState({
      currTime: this.formatAMPM(new Date())
    });
  }
  render() {
    return (
      <div className="container">
        <Header />
        <Nav />
        <div className="mainCnt">
          <div className="banner">
            <div className="bannerText">
              <p>PointCalimere</p>
              <p>-A Lesser Known destination</p>
            </div>
          </div>
          <div className="infoCnt">
            <div className="textCnt">
              <p className="genTitle2">Latest News</p>
              <p>Point Calimere (Kodikkarai) in Tamil is located 48 kms of Velankanni, 12kms to Vedaraniyam. Point Calimere is ideal place for bird watchers during the winter season when large flocks of migratory birds visit the Sanctuary. Point Calimere posses a ancient history, a epic called Ramayana has highlighted the significance of this coastal place.</p>
            </div>
            <div className="textCnt">
              <p className="genTitle2">Ramsar Site</p>
              <p>The convention on wetlands, called the Ramsar Convention, is an intergovermental treaty that provides the framework for national action and international co-opreation for the conservation and wise use of wetlands and their resources.</p>
            </div>
            <div className="textCnt">
              <p className="genTitle2">Time & Weather Report</p>
              <div className="timeDiv">
                <Clock />
                <div className="title">
                  <p><span className="weather">27</span><sup><sup>o</sup>C|<sup>o</sup>F</sup></p>
                  <p>{this.state.currDate}</p>
                  <p>Nagapattinam, TamilNadu</p>
                </div>
              </div>
            </div>
          </div>
          <div className="welcomeCnt">
            <div className="siteNear">
              <div className="card">
                <div className="textCnt">
                  <p>Bird Watching</p>
                  <p>Best from Oct-Feb</p>
                </div>
              </div>
              <div className="card">
                <div className="textCnt">
                  <p>Black Buck Siting</p>
                  <p>Round the year</p>
                </div>
              </div>
              <div className="card">
                <div className="textCnt">
                  <p>Heritage Places</p>
                  <p>Round the year</p>
                </div>
              </div>
            </div>
            <div className="welcomeTxt">
              <p className="genTitle2">Welcome to Pointcalimere</p>
              <p>Point Calimere (Kodikkarai) in Tamil is located 48 kms of Velankanni, 12kms to Vedaraniyam. Point Calimere is ideal place for bird watchers during the winter season when large flocks of migratory birds visit the Sanctuary. Point Calimere posses a ancient history, a epic called Ramayana has highlighted the significance of this coastal place</p>
              <div class="banglowCnt">
                <img src='./home-img.png' />
                <div className="banglowTxt">
                  <p className="genTitle2">About VMT Guest Bungalow</p>
                  <p>Our guest bungalow(V.M Thandapani Guest Bungalow) is situated very near to the Beach and the Wildlife Sanctuary. We provide a homely Atmosphere combined with all amenities at par available in the hosptilality industry which all customer expect in this place.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="facilitiesCnt">
            <p className="facilitiesTitle">Accomdations - Rooms, Facilities & Tariff</p>
            <div className="facilitiesWrap">
              <div>
                <ul className='facilitiesList'>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faLeaf} /></span><span>Restraunt Veg / Non-Veg</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faPowerOff} /></span><span>24 Hours Power Back-up</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faTrain} /></span><span>Travel Assitance</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faClock} /></span><span>24 Hours Room Service</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faBinoculars} /></span><span>Bird Watching Telescope</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faShip} /></span><span>Boat Riding (on request)</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faTaxi} /></span><span>Trip inside Sanctuary (on request)</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faToilet} /></span><span>Drivers Rest Room</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faBeer} /></span><span>Bar Lounge</span></div></li>
                </ul>
                <ul className="note">
                  <li>24 Hours Check-in/Check-out</li>
                  <li>Luxury taxes as applicable</li>
                  <li>Tariff subject to change without prior notice</li>
                </ul>
              </div>
              <div className="tarrifCnt">
                <p><span>Double Bed Room A/C (four occupancy)</span><span>RS. 1750/-</span></p>
                <p><span>Double Bed Room Non A/C (four occupancy)</span><span>RS. 1500/-</span></p>
                <p><span>Suit (Family Room A/C) (six occupancy)</span><span>RS. 2500/-</span></p>
                <p><span>Suit (Family Room Non A/C) (six occupancy)</span><span>RS. 2000/-</span></p>
                <p><span>Honeymoon Suit</span><span>RS. 2500/-</span></p>
                <p><span>Extra Bed</span><span>RS. 300/-</span></p>
                <div className="roomsCnt">
                  <BannerCarousel2 />
                </div>
              </div>
            </div>
          </div>
          <div className="welcomeCnt2">
            <div className="coursesTxt">
              <p className="coursesHead">Photography classes coming soon!</p>
              <div class="coursesImg">
                <img src='./POINTCALIMERE_AUG_071.jpg' />
                <div className="coursesSub">
                  <p>By renouned wildlife photographers of India</p>
                  <div class="btn btnPrimary">Know more</div>
                </div>
              </div>
            </div>
            <div className="siteNear">
              <div className="card">
                <div className="textCnt">
                  <p>VADUVUR</p>
                  <p>BIRD SANCTUARY</p>
                </div>
              </div>
              <div className="card">
                <div className="textCnt">
                  <p>KARAIVETTI</p>
                  <p>BIRD SANCTUARY</p>
                </div>
              </div>
              <div className="card">
                <div className="textCnt">
                  <p>MUTHUPET LAGOON</p>
                  <p>PICHAVARM BACK WATERS</p>
                </div>
              </div>
            </div>
          </div>
          <p className="genTitle" id="gallery">Photo gallery</p>
          <div className="galleryCnt">
            <div className="galleryWrap">
              <div className="galleryImg">
                <img src='./gallery-12-t.jpg' />
                <img src='./kodi_9_A_142.jpg' />
                <img src='./POINTCALIMERE_AUG_071.jpg' />
              </div>
              <div className="galleryImg">
                <img src='./point_22_031.jpg' />
                <img src='./gallery-13-t.jpg' />
                <img src='./mega_kili_show_417.jpg' />
              </div>
              <div className="galleryImg">
                <img src='./gallery-12-t.jpg' />
                <img src='./kodi_9_A_142.jpg' />
                <img src='./POINTCALIMERE_AUG_071.jpg' />
              </div>
            </div>
            <div className="videoGallery">
              <iframe width="100%" height="210" src="https://www.youtube.com/embed/iOm9TbVxF8Q" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
              <iframe width="100%" height="210" src="https://www.youtube.com/embed/Y3ECr_EZyuA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
            </div>
          </div>
          <div className="dropDown active">
            <div className="dropHead"><img src="./icon.png" /><p>Currency Converter</p></div>
            <CurrencyConverter />
          </div>
          <div className="dropDown">
            <div className="dropHead"><img src="./icon.png" /><p>Historical Places</p></div>
          </div>
          <div className="dropDown">
            <div className="dropHead"><img src="./icon.png" /><p>Gallery</p></div>
          </div>
          <div className="dropDown">
            <div className="dropHead"><img src="./icon.png" /><p>Contact Us</p></div>
          </div>
        </div>
        <Footer />
      </div >
    );
  }
}

export default Home;
