import React, { Component } from 'react';
import './currencyConverter.scss';
class CurrencyConverter extends Component {
    constructor() {
        super();

        this.state = {
            baseCurrency: 'INR',
            convertToCurrency: 'USD',
            baseAmount: 1,
            rates: [],
            currencies: []
        };

        this.changeBaseCurrency = this.changeBaseCurrency.bind(this);
        this.changeConvertToCurrency = this.changeConvertToCurrency.bind(this);
        this.changeBaseAmount = this.changeBaseAmount.bind(this);
        this.getConvertedCurrency = this.getConvertedCurrency.bind(this);
        this.callAPI = this.callAPI.bind(this);
    }

    componentDidMount() {
        this.callAPI(this.state.baseCurrency)
    }

    changeBaseCurrency(e) {
        this.setState({ baseCurrency: e.target.value });
        this.callAPI(e.target.value)

    }

    callAPI(base) {
        // const api = `https://api.exchangeratesapi.io/latest?base=${base}`;
        const api = 'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/inr.json';
        fetch(api)
            .then(results => {
                return results.json();
            }).then(data => this.setState({
                rates: data['inr'],
                currencies: Object.keys(data['inr']).sort()
            }));

    }


    changeConvertToCurrency(e) {
        this.setState({
            convertToCurrency: e.target.value
        });
    }

    changeBaseAmount(e) {
        this.setState({
            baseAmount: e.target.value
        });
    }

    getConvertedCurrency(baseAmount, convertToCurrency, rates) {
        return Number.parseFloat(baseAmount * rates[convertToCurrency]).toFixed(3);
    }

    render() {
        const { currencies, rates, baseCurrency, baseAmount, convertToCurrency } = this.state;

        const currencyChoice = currencies.map(currency =>
            <option key={currency} value={currency}> {currency} </option>
        );

        const result = this.getConvertedCurrency(baseAmount, convertToCurrency, rates);


        return (
            <div className="converter">
                <form className='ui mini form'>
                    <div>
                        <h3>Convert from: </h3>
                        <select value={baseCurrency} onChange={this.changeBaseCurrency}>
                            {currencyChoice}
                            <option>{baseCurrency}</option>
                        </select>
                        <h3>Convert to: </h3>
                        <select value={convertToCurrency} onChange={this.changeConvertToCurrency}>
                            {currencyChoice}
                        </select>
                    </div>
                    <div className="result">
                        <h3>Amount:</h3>
                        <input type='number'
                            id='base-amount'
                            defaultValue={baseAmount}
                            onChange={this.changeBaseAmount}>
                        </input>
                    </div>
                </form>
                <h2 className="resultText">{baseAmount} {baseCurrency} is equal to {result} {convertToCurrency}</h2>
            </div>
        );
    }
}

export default CurrencyConverter;