import React, { Component } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faWhatsappSquare, faFacebookSquare, faInstagramSquare, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import './header.scss';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faGooglePlusSquare, faInstagramSquare, faTwitterSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
class Header extends Component {
    constructor() {
        super();
    }
    render() {
        return (
            <div className="header" >
                <div className="nameHeader">
                    <img src='./icon.png' />
                    <div>
                        <p>VM THANDAPANI TOURIST BUNGALOW - Kodikkarai</p>
                        <p><b>Kodikkarai Alias Point Calimere - A Lesser Known Place</b></p>
                        <p>Phone:(+91) 04369-272299, 272211</p>
                        <p>Coustomer Care:(+91) 9940291199, 9566013299</p>
                    </div>
                </div>
                <div className="addressCnt">
                    <div className="socialMediaCnt">
                        <FontAwesomeIcon className="facebook" icon={faFacebookSquare} />
                        <FontAwesomeIcon className="twitter" icon={faTwitterSquare} />
                        <FontAwesomeIcon className="google" icon={faGooglePlusSquare} />
                        <FontAwesomeIcon className="insta" icon={faInstagramSquare} />
                        <FontAwesomeIcon className="youtube" icon={faYoutubeSquare} />
                    </div>
                    <p className="visitor">Visitor's Count&nbsp;:&nbsp;
                    <a href="https://www.freecounterstat.com/geozoom.php?c=3db09b678ba3a57d408750605a33cff2&base=counter6&type_clic=1" title="visitors counter" target="_blank">
                            <img src="https://counter8.stat.ovh/private/freecounterstat.php?c=zkmwn8jupgfe8u815bzw6zp9g6by64k6" border="0" title="view counter" alt="website view counter" />
                        </a>
                    </p>
                </div>
            </div>
        )
    }
}
export default Header;
