import React, { Component } from 'react';
import './nav.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnchorLink from 'react-anchor-link-smooth-scroll'
class Nav extends Component {
    constructor() {
        super();
    }
    render() {
        return (
            <div className="nav" >
                <div className="divCnt">
                    <span className="active">HOME</span>
                    <span><a href="#gallery">GALLERY</a></span>
                    <span>HISTORICAL PLACES</span>
                    <span>CONTACT</span>
                </div>
            </div>
        )
    }
}
export default Nav;
