import React from 'react';
import './footer.scss';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTripadvisor } from '@fortawesome/free-brands-svg-icons';
import { faTrain } from '@fortawesome/free-solid-svg-icons';
function Footer() {
  return (
    <div className="footer">
      <div className="footerCnt">
        <div className="taggingCnt">
          <div><p>Guest House in Pointcalimere</p><p>Kodikkarai</p></div>
          <div><p>Ramasar Site</p><p>Little kingdom by the coast</p></div>
          <div><p>Black Buck Siting</p><p>Velankanni</p></div>
          <div><p>VMT Guest House</p><p>Velankanni hotels</p></div>
        </div>
        <div className="menuCnt">
          <div className="menuItems">
            <span>Home</span>
            <span><a href="#gallery">Gallery</a></span>
            <span>Historical places</span>
            <span>Contact</span>
          </div>
          <a href="http://www.navagraham.com/" target="_blank">
            <img src="./navagrahamLogo.png" />
          </a>
          <p className="copyright">Copyright © 2020 www.velankannihotels.co
         | Powered by : RS Websolutions</p>
        </div>
        <div className="rightTaggingCnt">
          <p>Our guest bungalow(V.M Thandapani Guest Bungalow) is situated very near to the Beach and the Wildlife Sanctuary. We provide a homely Atmosphere combined with all amenities at par available in the hosptilality industry which all customer expect in this place.</p>
        </div>
        <div className="divineTour">

        </div>
      </div>
    </div>
  )
}
export default Footer;