import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './courosel.scss';
class BannerCarousel extends Component {
    render() {
        const getConfigurableProps = () => ({
            showArrows: false,
            infiniteLoop: true,
            showStatus: false,
            showThumbs: false,  
            showIndicators: false,                      
            autoPlay: true,
            stopOnHover: true,
            interval: 3000,
            transitionTime: 300,
            swipeScrollTolerance: 5
        });
        return (
            <div className="couroselCnt">
                <Carousel {...getConfigurableProps()}>
                    <div>
                        <img src='./room-1-t.jpg' />
                    </div>                    
                    <div>
                        <img src='./room-2-t.jpg' />
                    </div>
                    <div>
                        <img src='./room-3-t.jpg' />
                    </div>
                    <div>
                        <img src='./room-4-t.jpg' />
                    </div>
                    <div>
                        <img src='./room-8-t.jpg' />
                    </div>
                    <div>
                        <img src='./room-11-t.jpg' />
                    </div>
                    <div>
                        <img src='./room-14-t.jpg' />
                    </div>
                    <div>
                        <img src='./room-15-t.jpg' />
                    </div>
                </Carousel>
            </div>
        );
    }
}

export default BannerCarousel
